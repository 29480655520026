const uitls = {
  imageLoad(id: string): void {
    this.scrollBottom(id);
    const messageBox = document.getElementById(id);
    if (messageBox) {
      const images = messageBox.getElementsByTagName("img");
      if (images) {
        const arr: string[] = [];
        for (let i = 0; i < images.length; i++) {
          arr[i] = images[i].src;
        }
        this.preloadImages(arr).finally(() => {
          this.scrollBottom(id);
        });
      }
    }
  },
  preloadImages(arr: string[]): Promise<any> {
    let loadedImage = 0;
    return new Promise((resolve) => {
      for (let i = 0; i < arr.length; i++) {
        const image = new Image();
        image.src = arr[i];
        image.onload = () => {
          loadedImage++;
          if (loadedImage === arr.length) {
            resolve("");
          }
        };
        image.onerror = () => {
          loadedImage++;
          if (loadedImage === arr.length) {
            resolve("");
          }
        };
      }
    });
  },
  scrollBottom(id: string): void {
    const div = document.getElementById(id);
    if (div) {
      div.scrollTop = div.scrollHeight;
    }
  },
  // zeroValid表示0/"0"是否有效, false：无效
  isExist(value, zeroValid = false) {
    let flag = true;
    // 空字符串认为不存在
    /^\s*$/.test(value) && (flag = false);
    // 如果是一个数，并且0是有效值，认为是不存在的
    if (/^-*\d+(\.\d+)?$/.test(value)) {
      if ((value == 0 && !zeroValid) || value < 0) {
        flag = false;
      }
    } else {
      flag && (flag = !!value);
    }
    //空对象
    this.emptyObj(value) && (flag = false);
    //空数组
    Array.isArray(value) && value.length == 0 && (flag = false);
    return flag;
  },
  //判断是否是空对象
  emptyObj(obj) {
    const value = JSON.stringify(obj);
    return ["[]", "{}"].includes(value);
  },
  // 千分位分隔符
  parseToMoney(str, num = 2) {
    // return uni.$uc.parseToMoney(this.deci(str,this.getERPPars('deci_atm')));
    if (str < 0) {
      const source = String(this.deci(str, num)).split("."); //按小数点分成2部分
      source[0] = source[0].replace(new RegExp("(\\d)(?=(\\d{3})+$)", "ig"), "$1,"); //只将整数部分进行都好分割
      return source.join("."); //再将小数部分合并进来
    } else {
      let str1 = String(this.deci(str, num));
      str1 = this.isExist(str, true) ? str1 : "0";
      return str1.replace(/(?<!\..*)(?<=\d)(?=(\d{3})+(\.|$))/g, ",");
    }
  },
  //日期格式化
  getyyyyMMdd(fmt: any) {
    const d = new Date(fmt);
    let curr_date: any = d.getDate();
    let curr_month: any = d.getMonth() + 1;
    const curr_year = d.getFullYear();
    String(curr_month).length < 2 ? (curr_month = "0" + curr_month) : curr_month;
    String(curr_date).length < 2 ? (curr_date = "0" + curr_date) : curr_date;
    const yyyyMMdd = curr_year + "-" + curr_month + "-" + curr_date;
    return yyyyMMdd;
  },
  timeout: null,
  debounce(func, wait = 500, immediate = false) {
    // 清除定时器
    if (this.timeout !== null) clearTimeout(this.timeout);
    // 立即执行，此类情况一般用不到
    if (immediate) {
      const callNow = !this.timeout;
      this.timeout = setTimeout(() => {
        this.timeout = null;
      }, wait);
      if (callNow) typeof func === "function" && func();
    } else {
      // 设置定时器，当最后一次操作后，timeout不会再被清除，所以在延时wait毫秒后执行func回调方法
      this.timeout = setTimeout(() => {
        typeof func === "function" && func();
      }, wait);
    }
  },
  flag: false,
  timer: null,
  throttle(func, wait = 500, immediate = true) {
    if (immediate) {
      if (!this.flag) {
        this.flag = true;
        // 如果是立即执行，则在wait毫秒内开始时执行
        typeof func === "function" && func();
        this.timer = setTimeout(() => {
          this.flag = false;
        }, wait);
      }
    } else if (!this.flag) {
      this.flag = true;
      // 如果是非立即执行，则在wait毫秒内的结束处执行
      this.timer = setTimeout(() => {
        this.flag = false;
        typeof func === "function" && func();
      }, wait);
    }
  },
  // 保留多少位小数
  deci(value, deciNum = 2) {
    const baseNum = Math.pow(10, deciNum);
    return Number((Math.round(value * baseNum) / baseNum).toFixed(deciNum));
  },
  // 用于倒计时  计算差值
  timerFunction(options) {
    let { unit, differVal, startTime, endTime } = this.merge(
      {
        startTime: "", // 开始日期时间
        endTime: "", // 结束日期时间
        differVal: 0, //差值
        unit: "m", // m: 毫秒，s: 秒
      },
      options
    );
    unit = unit == "m" ? 1000 : 1;
    let num: any = differVal;
    if (startTime && endTime) {
      num = this.getDate(endTime).getTime() - this.getDate(startTime).getTime();
    }
    const oldNum: any = num;
    let interval = null;
    // 启动计时器函数
    return {
      start(callback) {
        num = oldNum;
        const day = parseInt((num / (unit * 3600 * 24)) as any);
        const hour = parseInt((num / (unit * 3600)) as any) % 24;
        const minute = parseInt((num / (unit * 60)) as any) % 60;
        const second = (num / unit) % 60;
        callback({
          day,
          hour,
          minute,
          second,
          num,
        });
        interval = setInterval(() => {
          num -= unit;
          num < 0 && clearInterval(interval);
          if (num >= 0) {
            callback({
              day,
              hour,
              minute,
              second,
              num,
            });
          }
        }, 1000);
      },
      // 清空定时器
      clear(callback) {
        clearInterval(interval);
        typeof callback == "function" && callback();
      },
    };
  },
  // 对象深拷贝
  merge(target, ...sources) {
    sources.forEach((source) => {
      Object.keys(source).forEach((key) => {
        const value = source[key];
        if (typeof value == "object" && value != null) {
          let newTarget = null;
          if (value instanceof Array) {
            newTarget = target[key] || [];
          } else {
            newTarget = target[key] || {};
          }
          target[key] = uitls.merge(newTarget, value);
        } else {
          target[key] = value;
        }
      });
    });
    return target;
  },
  dateFormat(timestamp, format) {
    if (String(timestamp).length === 10) {
      timestamp = timestamp * 1000;
    }
    const date = new Date(timestamp);
    const Y = date.getFullYear();
    const M = date.getMonth() + 1;
    const D = date.getDate();
    const hour = date.getHours();
    const min = date.getMinutes();
    const sec = date.getSeconds();
    if (format === "YYYY") {
      return Y; // 2021
    } else if (format === "YYYY-MM") {
      // 2021-07
      return Y + "-" + (M < 10 ? "0" + M : M);
    } else if (format === "YYYY-MM-DD") {
      // 2021-07-12
      return Y + "-" + (M < 10 ? "0" + M : M) + "-" + (D < 10 ? "0" + D : D);
    } else if (format === "HH:mm:ss") {
      // 10:20:35
      return (hour < 10 ? "0" + hour : hour) + ":" + (min < 10 ? "0" + min : min) + ":" + (sec < 10 ? "0" + sec : sec);
    } else if (format === "YYYY-MM-DD HH:mm") {
      // 2021-07-12 10:20
      return (
        Y +
        "-" +
        (M < 10 ? "0" + M : M) +
        "-" +
        (D < 10 ? "0" + D : D) +
        " " +
        (hour < 10 ? "0" + hour : hour) +
        ":" +
        (min < 10 ? "0" + min : min)
      );
    } else if (format === "YYYY-MM-DD HH:mm:ss") {
      // 2021-07-12 10:20:35
      return (
        Y +
        "-" +
        (M < 10 ? "0" + M : M) +
        "-" +
        (D < 10 ? "0" + D : D) +
        " " +
        (hour < 10 ? "0" + hour : hour) +
        ":" +
        (min < 10 ? "0" + min : min) +
        ":" +
        (sec < 10 ? "0" + sec : sec)
      );
    } else {
      return "--";
    }
  },
};
export default function (app: any) {
  app.config.globalProperties.$utils = uitls;
}
