import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    component: () => import("@/components/login.vue"),
  },
  {
    path: "/register",
    component: () => import("@/components/register.vue"),
  },
  {
    path: "/.*",
    name: "NotFound",
    component: () => import("@/components/error.vue"),
  },
  {
    path: "/login/h5",
    component: () => import("@/components/h5Login.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes as any,
});

export default router;
